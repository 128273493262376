import React from 'react'
import './TopNav.scss'
import CitySelector from './CitySelector'
import MarketSelector from './MarketSelector'
import UserMenu from './UserMenu'


export default function TopNav() {
    return (
        <nav className="top-nav">
            <CitySelector />
            <MarketSelector />
            <UserMenu />
        </nav>
    )
}