import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { jwtDecode } from 'jwt-decode'


export interface AuthState {
  token: string
}

type AccessTokenPayload = {
  uuid: string
  email: string
  exp: number
}

const initialState: AuthState = {
  token: ''
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    removeToken: (state, action: PayloadAction<void>) => {
      state.token = ''
    }
  }
})

export const { setToken, removeToken } = authSlice.actions

export const getAccessToken = (state: RootState): string => state.auth.token
export const getUserUuid = (state: RootState): string => (jwtDecode(state.auth.token) as AccessTokenPayload).uuid
export const isLoggedIn = (state: RootState): boolean => {
  if (state.auth.token) {
    const decodedToken = jwtDecode(state.auth.token) as AccessTokenPayload
    return decodedToken.exp > new Date().getTime()
  }
  return false
}

export default authSlice.reducer
