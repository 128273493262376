import { createBrowserRouter, LoaderFunction } from 'react-router-dom'
import Root from '../Root'
import ErrorPage from '../components/common/ErrorPage'
import {
  BASE_PRODUCT_ROUTE,
  CATEGORY_ROUTE,
  PRODUCT_ROUTE,
  PRODUCT_PACKAGE_ROUTE,
  SAVED_SHOPPING_LIST_ROUTE,
  SAVED_SHOPPING_LISTS_ROUTE,
  SEARCH_ROUTE,
  SHOPPING_LIST_ROUTE
} from './routes'

export default createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: SEARCH_ROUTE,
        lazy: async () => {
          const { SearchView } = await import('../components/search/SearchView')
          return {
            Component: SearchView
          }
        },
        errorElement: <ErrorPage />
      },
      {
        path: CATEGORY_ROUTE,
        lazy: async () => {
          const { ProductsListViewCategoryLoader, ProductsListView } = await import('../components/ProductsListView')
          return {
            loader: ProductsListViewCategoryLoader,
            Component: ProductsListView,
          }
        },
        errorElement: <ErrorPage />
      },
      {
        path: BASE_PRODUCT_ROUTE,
        lazy: async () => {
          const { ProductsListViewBaseProductLoader, ProductsListView } = await import('../components/ProductsListView')
          return {
            loader: ProductsListViewBaseProductLoader,
            Component: ProductsListView,
          }
        },
        errorElement: <ErrorPage />
      },
      {
        path: PRODUCT_ROUTE,
        lazy: async () => {
          const { ProductViewLoader, ProductView } = await import('../components/product-page/ProductView')
          return {
            loader: ProductViewLoader,
            Component: ProductView,
          }
        },
        errorElement: <ErrorPage />
      },
      {
        path: PRODUCT_PACKAGE_ROUTE,
        lazy: async () => {
          const { ProductViewLoader, ProductView } = await import('../components/product-page/ProductView')
          return {
            loader: ProductViewLoader,
            Component: ProductView,
          }
        },
        errorElement: <ErrorPage />
      },
      {
        path: SHOPPING_LIST_ROUTE,
        lazy: async () => {
          const { ShoppingListView } = await import('../components/shopping-list/ShoppingListView')
          return {
            Component: ShoppingListView
          }
        },
        errorElement: <ErrorPage />
      },
      {
        path: SAVED_SHOPPING_LISTS_ROUTE,
        lazy: async () => {
          const { SavedShoppingListsView } = await import('../components/shopping-list/SavedShoppingListsView')
          return {
            Component: SavedShoppingListsView
          }
        },
        errorElement: <ErrorPage />
      }
    ]
  },
  {
    path: SAVED_SHOPPING_LIST_ROUTE,
    lazy: async () => {
      const { SavedShoppingListLoader, SavedShoppingListView } = await import('../components/saved-shopping-list/SavedShoppingListView')
      return {
        loader: SavedShoppingListLoader as unknown as LoaderFunction,
        Component: SavedShoppingListView,
      }
    },
    errorElement: <ErrorPage />
  },
])