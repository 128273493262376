import React, { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react'
import './Login.scss'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ReCaptchaPolices from './ReCaptchaPolices'
import endpoints from '../../../api/endpoints';
import useApi from '../../../api/request';


interface LoginStageAuthProps {
  uuid: string,
  email: string,
  back: () => void
  onSubmit: (token: string) => void
}

export default function LoginStageAuth(props: LoginStageAuthProps) {
  const [code, setCode] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [login, { loading }] = useApi(endpoints.user_auth, {
    method: 'POST',
    onCompleted: (data: { token: string }) => props.onSubmit(data.token)
  })

  const submit = () => {
    handleReCaptchaVerify().then(reCaptchaToken => {
      login({
        body: {
          uuid: props.uuid,
          code: parseInt(code)
        },
        headers: {
          Recaptcha: reCaptchaToken
        }
      })
    })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event

    setIsValid(value.length === 6);
    setCode(value)
  }

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    return await executeRecaptcha('preLogin')
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  return (
    <Fragment>
      <DialogTitle sx={{ textAlign: 'center' }}>Почти сте готови...</DialogTitle>
      <DialogContent>
        <DialogContentText>
          На имейл <b>{props.email}</b> е изпратен 6-цифрен код. Въведете го тук, за да влезете в профила си!
        </DialogContentText>
        <TextField
          className="code-input"
          label="6-цифрен код за сигурност"
          variant="outlined"
          sx={{ marginTop: '20px' }}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions sx={{
        justifyContent: 'center'
      }}>
        <Button variant="outlined" color="error" onClick={props.back}>Назад</Button>
        <Button variant="contained" color="success" disabled={!isValid || loading} onClick={submit}>Вход</Button>
      </DialogActions>
      <ReCaptchaPolices />
    </Fragment>
  )
}