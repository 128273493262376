import React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import './ErrorPage.scss'

export default function ErrorPage() {
  const error = useRouteError();

  console.log(error);
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      console.log(404);
      return (
        <section className="error-page">This page doesn't exist!</section>
      )
    }

    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }

    if (error.status === 418) {
      return <div>🫖</div>;
    }
  }

  return (
    <div>
      Грешка
    </div>
  );
}