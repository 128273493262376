import React, {Fragment} from "react"
import './Login.scss'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material"

interface LoginStageFailedProps {
    back: () => void
}

export default function LoginStageFailed(props: LoginStageFailedProps) {
    return (
        <Fragment>
            <DialogTitle sx={{textAlign: 'center'}}>Неуспешен вход</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Въведеният код не е правилен. Върнете се назад и въведете имейла си отново, за да получите нов код!
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{
                justifyContent: 'center'
            }}>
                <Button variant="outlined" color="error" onClick={props.back}>Назад</Button>
            </DialogActions>
        </Fragment>
    )
}