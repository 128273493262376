import { useRef, useState } from 'react'
import { BASE_URL } from '../routing/routes'

const API_URL = BASE_URL + '/api'

type RequestMethod = 'GET' | 'POST'

type ApiConfig = {
  method?: RequestMethod
  onCompleted?: (data: any) => void
}

type RequestConfig = {
  variables?: any
  headers?: any
  body?: any

  method?: RequestMethod
}

// TODO refactor

export async function useFetch(url: string, requestConfig: RequestConfig = {}): Promise<any | null> {
  const variables = requestConfig.variables || []

  Object.keys(variables).forEach(key => {
    url = url.replace(':' + key, variables[key])
  })

  try {
    const response = await fetch(API_URL + url, buildRequestInit(requestConfig, requestConfig.method))

    if (response.ok) {
      const data = await response.json()

      return data.data
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export default function useApi(url: string, apiConfig: ApiConfig = {}): [(variables?: any) => void, { loading: boolean | undefined, error: boolean }] {
  const called = useRef(false)
  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  const [loading, setLoading] = useState<boolean | undefined>(undefined)
  const [error, setError] = useState(false)

  const call = async (requestConfig: RequestConfig = {}) => {
    if (called.current) {
      return
    }

    called.current = true
    setLoading(true)

    const variables = requestConfig.variables || []

    Object.keys(variables).forEach(key => {
      url = url.replace(':' + key, variables[key])
    })

    try {
      const response = await fetch(API_URL + url, buildRequestInit(requestConfig, apiConfig.method))

      if (response.ok) {
        const data = await response.json()

        if (apiConfig.onCompleted) {
          apiConfig.onCompleted(data.data)
        }
      } else {
        setError(true)
      }
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)

      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }

      timeoutId.current = setTimeout(() => {
        called.current = false
        timeoutId.current = null
      }, 300)
    }
  }

  return [call, {
    loading,
    error
  }]
}

const buildRequestInit = (config: RequestConfig, method?: RequestMethod): RequestInit => {
  let requestInit: RequestInit = {
    method: method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...config.headers || {}
    }
  }

  if (config.body) {
    requestInit.body = JSON.stringify(config.body || {})
  }

  return requestInit
}