import React from 'react'
import './Loaders.scss'
import Box from "@mui/material/Box"
import {Grid, Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"

export function ProductsListViewLoader(props: { aside: boolean }) {
    const filtersContainer = props.aside ? (
        <div className="loading-box">
            <Box sx={{width: 220, paddingLeft: 1}}>
                <Typography variant="h2"><Skeleton /></Typography>
                <Typography variant="h4" sx={{marginTop: 2}}><Skeleton /></Typography>
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Box>
        </div>
    ) : (
        <div className="loading-box">
            <Box sx={{height: 100, width: 320, paddingLeft: 1}}>
                <Typography variant="h4" sx={{marginTop: 2}}><Skeleton /></Typography>
                <Skeleton />
            </Box>
        </div>
    )

    return (
        <section className="main-content category-view">
            {filtersContainer}
            <Grid container>
                {(Array.from(new Array(3))).map((item, index) => (
                    <Box key={index} sx={{ width: 320, marginRight: 1.5, my: 2 }}>
                        <Skeleton variant="rectangular" width={320} height={380} />
                        <Box sx={{ pt: 0.5 }}>
                            <Skeleton />
                            <Skeleton width="60%" />
                        </Box>
                    </Box>
                ))}
            </Grid>
        </section>
    )
}
