import React, {Fragment, useState} from "react"
import './MobileMenu.scss'
import {Link} from "react-router-dom"
import {Divider, Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material"
import Box from "@mui/material/Box"
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import {CATEGORY_DRUGSTORE_ROUTE, CATEGORY_FOOD_ROUTE, CATEGORY_HOUSEHOLD_GOODS_ROUTE} from "../../routing/routes"
import MenuItem from "@mui/material/MenuItem"
import PersonAdd from "@mui/icons-material/PersonAdd"
import Logout from "@mui/icons-material/Logout"
import {isLoggedIn, removeToken} from "../../redux/authSlice"
import {useAppDispatch, useAppSelector} from "../../redux/hooks"
import {Login as LoginIcon} from "@mui/icons-material"
import Login from "../common/login/Login"

interface MobileMenuProps {
    isOpen: boolean
    toggleMenu: (open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => void
}

export default function MobileMenu(props: MobileMenuProps) {
    const dispatch = useAppDispatch()
    const loggedIn = useAppSelector(isLoggedIn)
    const [openLoginDialog, setOpenLoginDialog] = useState(false)

    const logout = () => {
        dispatch(removeToken())
    }

    return (
        <Fragment>
            <Drawer
                anchor="left"
                open={props.isOpen}
                onClose={props.toggleMenu(false)}
            >
                <Box
                    className="mobile-menu"
                    sx={{ width: 280 }}
                    role="presentation"
                    onClick={props.toggleMenu(false)}
                    onKeyDown={props.toggleMenu(false)}
                >
                    <ListItem key="categories-header" className="group-header">Категории</ListItem>
                    <ListItem key="food-link" disablePadding>
                        <ListItemButton>
                            <ListItemIcon className="icon"><KeyboardArrowRightRoundedIcon /></ListItemIcon>
                            <Link to={CATEGORY_FOOD_ROUTE} className="category-link">
                                <ListItemText primary="Хранителни продукти" />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="household-goods-link" disablePadding>
                        <ListItemButton>
                            <ListItemIcon className="icon"><KeyboardArrowRightRoundedIcon /></ListItemIcon>
                            <Link to={CATEGORY_HOUSEHOLD_GOODS_ROUTE} className="category-link">
                                <ListItemText primary="Домашни потреби" />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="drugstore-link" disablePadding>
                        <ListItemButton>
                            <ListItemIcon className="icon"><KeyboardArrowRightRoundedIcon /></ListItemIcon>
                            <Link to={CATEGORY_DRUGSTORE_ROUTE} className="category-link">
                                <ListItemText primary="Козметика и дрогерия" />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem key="profile-header" className="group-header">Профил</ListItem>
                    { loggedIn ?
                        <Fragment>
                            {/*<ListItem key="profile-city-stores" disablePadding>*/}
                            {/*    <ListItemButton>*/}
                            {/*        <ListItemIcon className="icon"><SettingsIcon /></ListItemIcon>*/}
                            {/*        <Link to="/настройки" className="category-link">*/}
                            {/*            <ListItemText primary="Град и магазини" />*/}
                            {/*        </Link>*/}
                            {/*    </ListItemButton>*/}
                            {/*</ListItem>*/}
                            <ListItem key="profile-saved-shopping-lists" disablePadding>
                                <ListItemButton>
                                    <ListItemIcon className="icon"><PersonAdd /></ListItemIcon>
                                    <Link to="/saved-lists">
                                        <ListItemText primary="Списъци за пазарене" />
                                    </Link>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="profile-logout" disablePadding onClick={logout}>
                                <ListItemButton>
                                    <ListItemIcon className="icon"><Logout /></ListItemIcon>
                                    <ListItemText primary="Изход" />
                                </ListItemButton>
                            </ListItem>
                            {/*<ListItem key="settings-profile" disablePadding>*/}
                            {/*    <ListItemButton>*/}
                            {/*        <ListItemIcon className="icon"><PersonRoundedIcon /></ListItemIcon>*/}
                            {/*        <Link to="/профил" className="category-link">*/}
                            {/*            <ListItemText primary="Профил" />*/}
                            {/*        </Link>*/}
                            {/*    </ListItemButton>*/}
                            {/*</ListItem>*/}
                        </Fragment> :
                        <Fragment>
                            <ListItem key="profile-logout" disablePadding onClick={() => setOpenLoginDialog(true)}>
                                <ListItemButton>
                                    <ListItemIcon className="icon"><LoginIcon /></ListItemIcon>
                                    <ListItemText primary="Вход" />
                                </ListItemButton>
                            </ListItem>
                        </Fragment>
                    }
                </Box>
            </Drawer>
            { openLoginDialog ? <Login onClose={() => setOpenLoginDialog(false)} /> : ''}
        </Fragment>
    )
}