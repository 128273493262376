import React from 'react'
import './Root.scss'
import 'react-tooltip/dist/react-tooltip.css'
import {RouterProvider} from 'react-router-dom'
import router from './routing/router'   

export default function App() {
    // TODO
    // if logged => load city and markets
    // else => get city from Geo data and filter possible markets, then store them to localStorage

    return (
        <RouterProvider router={router} />
    );
}