import React, {useState} from "react"
import './CitySelector.scss'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem, Select,
} from "@mui/material"
import {useAppDispatch, useAppSelector} from "../../../redux/hooks"
import {getSelectedCity, setSelectedCity} from "../../../redux/configSlice"
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import DialogTransitionDown from "../../common/DialogTransitionDown"

const cities: {[key: number]: string} = {
    1: 'Бургас',
    2: 'София',
    3: 'Русе'
}


export default function CitySelector() {
    const dispatch = useAppDispatch()
    const cityId = useAppSelector((state) => getSelectedCity(state))

    const [open, setOpen] = useState(false)
    const [cityIdSelector, setCityIdSelector] = useState(cityId)

    const close = () => {
        setCityIdSelector(cityId)
        setOpen(false)
    }
    const submit = () => {
        setOpen(false)
        dispatch(setSelectedCity(cityIdSelector))
    }

    const cityOptions = []
    for (let key of Object.keys(cities)) {
        const id = parseInt(key)
        cityOptions.push(<MenuItem key={id} selected={id === cityIdSelector} value={id}>{cities[id]}</MenuItem>)
    }

    return (
        <div>
            <div className="button city" onClick={() => setOpen(true)}>
                <LocationOnRoundedIcon className="icon"></LocationOnRoundedIcon>
                <div className="text">{cities[cityId]}</div>
            </div>
            <Dialog
                open={open}
                TransitionComponent={DialogTransitionDown}
                keepMounted
                onClose={close}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="city-selector-dialog">
                    <DialogTitle>Избор на град</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Изберете града, за който да виждате акуални цени и наличности.
                        </DialogContentText>
                        <Select
                            className="selector"
                            value={cityIdSelector}
                            onChange={(e) => setCityIdSelector(parseInt(e.target.value.toString()))}
                            displayEmpty
                        >
                            {cityOptions}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="error" startIcon={<ClearIcon />} onClick={close}>Отказ</Button>
                        <Button variant="contained" color="success" startIcon={<CheckIcon />} onClick={submit}>Потвърди</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}