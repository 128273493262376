import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from "./store"


export interface ConfigState {
  city: number,
  markets: number[]
}

const initialState: ConfigState = {
  city: 0,
  markets: []
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setSelectedCity: (state, action: PayloadAction<number>) => {
      state.city = action.payload
    },
    setSelectedMarkets: (state, action: PayloadAction<number[]>) => {
      state.markets = action.payload
    }
  }
})

export const { setSelectedCity, setSelectedMarkets } = configSlice.actions

export const getSelectedCity = (state: RootState): number => state.config.city
export const getSelectedMarkets = (state: RootState): number[] => state.config.markets

export default configSlice.reducer
