import { Link } from 'react-router-dom'
import './Footer.scss'

export default function Footer(props: {}) {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer">
      <div className="about">
        <Link to="">Общи условия</Link>
        <Link to="">Лични данни</Link>
        {/* <Link to="">Поверителност</Link>  */}
        <Link to="">Бисквитки</Link>
        <Link to="">Контакти</Link>
      </div>
      <div className="disclaimer">
        „На промоция“ ЕООД не носи отговорност за несъответсвия в цените посочение на сайта и реалните цени прилагани от търговците, валидността и обхвата на обявените промоции, коректността на информацията за продуктите, както и всякакви грешки в описанието на продуктите или обявените цени.
      </div>
      <div className="rights-reserved">
        © Napromotsia.bg На промоция ЕООД 2024-{currentYear}
      </div>
    </footer>
  )
}