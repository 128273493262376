import React, { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react'
import './Login.scss'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Checkbox } from '../Checkbox'
import ReCaptchaPolices from './ReCaptchaPolices'
import useApi from '../../../api/request';
import endpoints from '../../../api/endpoints';


const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

interface LoginStageStrangerProps {
  loginReasonText?: string
  onSubmit: (uuid: string, email: string) => void
}

export default function LoginStageStranger(props: LoginStageStrangerProps) {
  const [email, setEmail] = useState('')
  const [agree, setAgree] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [preLogin, { loading }] = useApi(endpoints.user_preAuth, {
    method: 'POST',
    onCompleted: (data: { uuid: string }) => {
      if (data.uuid) {
        props.onSubmit(data.uuid, email)
        setErrorMessage('')
      } else {
        setErrorMessage('Нещо се обърка')
      }
    }
  })

  const submit = () => {
    handleReCaptchaVerify().then(reCaptchaToken => {
      preLogin({
        body: {
          email: email
        },
        headers: {
          Recaptcha: reCaptchaToken
        }
      })
    })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event

    setIsValid(EMAIL_REGEX.test(value));
    setEmail(value)
  }

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    return await executeRecaptcha('preLogin')
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <Fragment>
      <DialogTitle sx={{ textAlign: 'center' }}>Вход в профил</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }}>
          Влезте в профила си или създайте нов{props.loginReasonText ? ', за да ' + props.loginReasonText : ''}!<br />
          Отнема само 30 секунди и Ви е нужен само имейл.
        </DialogContentText>
        <TextField
          className="email-input"
          label="Имейл"
          variant="outlined"
          sx={{ marginTop: '20px' }}
          onChange={handleChange}
        />
        <div className="agreement-checkboxes">
          <Checkbox
            id="agree"
            text="Приемам условията за ползване и декларацията за поверителност."
            type="checkbox"
            checked={agree}
            onChange={(_, state) => setAgree(state)} />
        </div>
        {errorMessage}
      </DialogContent>
      <DialogActions sx={{
        justifyContent: 'center'
      }}>
        <Button variant="contained" color="success" disabled={!isValid || loading || !agree} endIcon={<ArrowForwardIosIcon />} onClick={submit}>Напред</Button>
      </DialogActions>
      <ReCaptchaPolices />
    </Fragment>
  )
}