import React, {Fragment, useState} from "react"
import './UserMenu.scss'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import Login from "../../common/login/Login"
import {useAppDispatch, useAppSelector} from "../../../redux/hooks"
import {isLoggedIn, removeToken} from "../../../redux/authSlice"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Logout from '@mui/icons-material/Logout';
import {Link} from "react-router-dom"

export default function UserMenu() {
    const dispatch = useAppDispatch()
    const loggedIn = useAppSelector(isLoggedIn)
    const [openLoginDialog, setOpenLoginDialog] = useState(false)
    const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElement(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorElement(null);
    }

    const logout = () => {
        dispatch(removeToken())
        setOpenLoginDialog(false)
    }

    return (
        <Fragment>
            { loggedIn ?
                <Fragment>
                    <div className="button profile" onClick={handleClick}>
                        <PersonRoundedIcon className="icon"></PersonRoundedIcon>
                        <div className="text">Профил</div>
                    </div>
                    <Menu
                        className="user-menu"
                        anchorEl={anchorElement}
                        id="account-menu"
                        open={!!anchorElement}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <Link to="/saved-lists">
                            <MenuItem>
                                <ListItemIcon>
                                    <PersonAdd fontSize="small" />
                                </ListItemIcon>
                                Списъци за пазарене
                            </MenuItem>
                        </Link>
                        <Divider />
                        <MenuItem onClick={logout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Изход
                        </MenuItem>
                    </Menu>
                </Fragment> :
                <div className="button profile">
                    <PersonRoundedIcon className="icon"></PersonRoundedIcon>
                    <div className="text" onClick={() => setOpenLoginDialog(true)}>Вход</div>
                    { openLoginDialog ? <Login onClose={() => setOpenLoginDialog(false)} /> : ''}
                </div>
                }
        </Fragment>
    )
}