import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import Product, {ProductPackage} from "../entities/Product"
import {
  buildCombinations,
  ShoppingListCombination,
  ShoppingListProducts
} from "../services/ShoppingListManager"
import {ShoppingListState} from "./shoppingListSlice"

interface SetStatusActionPayload {
  uuid: string
  packageId: number
  status: boolean
}
interface SavedShoppingListState {
  uuid: string
  packageStatus: {[key: number]: boolean}
}

const initialState: SavedShoppingListState = {
  uuid: '',
  packageStatus: {}
}

export const savedShoppingListSlice = createSlice({
  name: 'savedShoppingList',
  initialState,
  reducers: {
    setStatusIfAbsent: (state: SavedShoppingListState, action:PayloadAction<SetStatusActionPayload>) => {
      if (state.uuid !== action.payload.uuid) {
        // state = {...initialState}
        state.uuid = action.payload.uuid
        state.packageStatus = {}
      }

      if (state.packageStatus[action.payload.packageId] === undefined) {
        state.packageStatus[action.payload.packageId] = action.payload.status
      }
    },
    switchStatus: (state: SavedShoppingListState, action:PayloadAction<number>) => {
      state.packageStatus[action.payload] = !state.packageStatus[action.payload]
    }
  }
})

export const { setStatusIfAbsent, switchStatus } = savedShoppingListSlice.actions
export const getSelected = (state: RootState): number[] => {
  return Object.keys(state.savedShoppingList.packageStatus)
      .map(packageId => parseInt(packageId))
      .filter(packageId =>  state.savedShoppingList.packageStatus[packageId])
}
export default savedShoppingListSlice.reducer
