import React from 'react'
import './ReCaptchaPolices.scss'


export default function ReCaptchaPolices(props: {}) {
    return (
        <div className="recaptcha-polices">
            Този сайт е защитен чрез reCAPTCHA и се прилагат <a href="https://policies.google.com/privacy" target="_blank">Политиката за поверителност</a> и <a href="https://policies.google.com/terms" target="_blank">Условията за ползване</a> на Google.
        </div>
    )
}