import React, { useState } from "react"
import './Login.scss'
import { Dialog } from "@mui/material"
import { isLoggedIn, setToken } from "../../../redux/authSlice"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import DialogTransitionDown from "../DialogTransitionDown"
import LoginStageStranger from "./LoginStageStranger"
import LoginStageAuth from "./LoginStageAuth"
import LoginStageFailed from "./LoginStageFailed"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

enum LoginStage {
  STRANGER,
  AUTH,
  LOGGED_IN, // should be always closed
  LOGIN_FAILED
}

interface LoginProps {
  loginReasonText?: string
  onClose: () => void
}

export default function Login(props: LoginProps) {
  const dispatch = useAppDispatch()
  const loggedIn = useAppSelector(isLoggedIn)
  const [uuid, setUuid] = useState('')
  const [email, setEmail] = useState('')
  const [open, setOpen] = useState(!loggedIn)
  const [stage, setStage] = useState(loggedIn ? LoginStage.LOGGED_IN : LoginStage.STRANGER)

  const close = () => {
    setOpen(false)
    props.onClose()
  }
  const onPreLogin = (uuid: string, email: string) => {
    setUuid(uuid)
    setEmail(email)
    setStage(LoginStage.AUTH)
  }

  const onLogin = (token: string) => {
    if (token) {
      dispatch(setToken(token))
      setStage(LoginStage.LOGGED_IN)
      setOpen(false)
      props.onClose()
    } else {
      setStage(LoginStage.LOGIN_FAILED)
    }
  }

  const getContent = () => {
    if (stage === LoginStage.STRANGER) {
      return <LoginStageStranger loginReasonText={props.loginReasonText} onSubmit={onPreLogin} />
    } else if (stage === LoginStage.AUTH) {
      return <LoginStageAuth
        uuid={uuid}
        email={email}
        back={() => setStage(LoginStage.STRANGER)}
        onSubmit={onLogin} />
    } else if (stage === LoginStage.LOGIN_FAILED) {
      return <LoginStageFailed back={() => setStage(LoginStage.STRANGER)} />
    }
    // no content on LoginStage.LOGGED_IN - the dialog have to be closed
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransitionDown}
      keepMounted
      onClose={close}
    >
      <GoogleReCaptchaProvider
        scriptProps={{
          defer: true,
          appendTo: 'body'
        }}
        reCaptchaKey="6LckK0kpAAAAACr_WYsRTTzI_EOWbkTG7rOSAJFq"
        language="bg"
      >
        {getContent()}
      </GoogleReCaptchaProvider>
    </Dialog>
  )
}