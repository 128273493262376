import React from 'react'
import './Checkbox.scss'

export type CheckboxType = 'radio' | 'checkbox'

interface CheckboxProps {
    id: any,
    text: string
    type: CheckboxType,
    checked: boolean,
    onChange: (id: any, state: boolean) => void
}
export function Checkbox(props: CheckboxProps) {
    const handleChange = () => {
        if (props.type === 'checkbox' || !props.checked) {
            const newState = !props.checked
            props.onChange(props.id, newState)
        }
    }

    return (
        <div onClick={handleChange} className="checkbox-wrapper">
            <input type={props.type} checked={props.checked} readOnly={true} onClick={(e) => e.stopPropagation()} id={'checkbox-' + props.id} />
            <label htmlFor={'checkbox-' + props.id}/>
            {props.text}
        </div>

    )
}
