// base

import BaseProduct from "../entities/BaseProduct"
import { Category } from "../entities/Category"
import Product, { ProductPackage } from "../entities/Product"

// TODO make it with env var
export const BASE_URL = 'https://napromotsia.bg'

// search
export const SEARCH_ROUTE = '/search'
export const getLinkToSearch = (term: string, external?: boolean) => {
  return (external ? BASE_URL : '') + SEARCH_ROUTE + '?term=' + term
}

// categories
export const CATEGORY_ROUTE_PLACEHOLDER = 'categoryUrlName'
export const CATEGORY_ROUTE = '/c/:' + CATEGORY_ROUTE_PLACEHOLDER
export const CATEGORY_FOOD_ROUTE = '/c/хранителни-продукти-151'
export const CATEGORY_HOUSEHOLD_GOODS_ROUTE = '/c/домашни-потреби-12'
export const CATEGORY_DRUGSTORE_ROUTE = '/c/козметика-и-дрогерия-11'
export const getLinkToCategory = (category: Category, external?: boolean) => {
  return (external ? BASE_URL : '') + CATEGORY_ROUTE.replace(':' + CATEGORY_ROUTE_PLACEHOLDER, category.urlName)
}

// base products
export const BASE_PRODUCT_ROUTE_PLACEHOLDER = 'baseProductUrlName'
export const BASE_PRODUCT_ROUTE = '/bp/:' + BASE_PRODUCT_ROUTE_PLACEHOLDER
export const BASE_PRODUCT_ROUTE_PATTERN = (urlName: string): string => '/bp/' + urlName
export const getLinkToBaseProduct = (baseProduct: BaseProduct, external?: boolean) => {
  return getLinkToBaseProductByUrlName(baseProduct.urlName, external)
}
export const getLinkToBaseProductByUrlName = (urlName: string, external?: boolean) => {
  return (external ? BASE_URL : '') + BASE_PRODUCT_ROUTE.replace(':' + BASE_PRODUCT_ROUTE_PLACEHOLDER, urlName)
}

// product
export const PRODUCT_ROUTE_PLACEHOLDER = 'productUrlName'
export const PRODUCT_PACKAGE_ROUTE_PLACEHOLDER = 'productPackageId'
export const PRODUCT_ROUTE = '/p/:' + PRODUCT_ROUTE_PLACEHOLDER
export const PRODUCT_PACKAGE_ROUTE = '/p/:' + PRODUCT_ROUTE_PLACEHOLDER + '/:' + PRODUCT_PACKAGE_ROUTE_PLACEHOLDER
export const getLinkToProduct = (product: Product, productPackage?: ProductPackage, external?: boolean) => {
  return (external ? BASE_URL : '') +
   PRODUCT_ROUTE.replace(':' + PRODUCT_ROUTE_PLACEHOLDER, product.urlName) + 
   (productPackage ? '/' + productPackage.id : '')
}

// shopping list
export const SHOPPING_LIST_ROUTE = '/shopping-list'
export const SAVED_SHOPPING_LISTS_ROUTE = '/saved-lists'
export const SHOPPING_LIST_UUID_ROUTE_PLACEHOLDER = 'shoppingListUuid'
export const SAVED_SHOPPING_LIST_ROUTE = '/shop/:' + SHOPPING_LIST_UUID_ROUTE_PLACEHOLDER
export const getLinkToSavedShoppingList = (uuid: string, external: boolean) => {
    return (external ? BASE_URL : '') + '/shop/' + uuid
}